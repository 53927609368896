import {
  DemoResetInfo,
  DemoUserInfo,
  DemoUserResetDto,
} from "../../types/demo/DemoUserResetTypes";
import GenericApiResult from "../../types/dtos/generic/GenericApiResult";
import { apiClient } from "../apiClient";

class demoApi {
  constructor(authToken: string | undefined) {
    this.accessToken = authToken;
  }

  accessToken: string | undefined;

  resetManagerDemo(
    details: DemoUserResetDto[],
    onSuccess: (data: GenericApiResult) => void,
    onError: (error: any) => void
  ) {
    return apiClient(this.accessToken, "dashboards/demo/reset-manager-demo", {
      body: JSON.stringify(details),
    }).then(
      (data) => {
        const result = data as GenericApiResult;
        onSuccess(result);
      },
      (error) => {
        onError(error);
      }
    );
  }

  /** Get the people assigned to the logged in user */
  getDemoResetInfo(
    onSuccess: (demoInfo: DemoResetInfo) => void,
    onError: (error: any) => void
  ) {
    return apiClient(
      this.accessToken,
      `dashboards/demo/get-demo-reset-info`
    ).then(
      (data) => {
        const demoInfo = data as DemoResetInfo;
        onSuccess(demoInfo);
      },
      (error) => {
        onError(error);
      }
    );
  }
}

export default demoApi;
