import { useContext, useState } from "react";
import { useEffectOnce } from "react-use";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "react-oidc-context";
import produce from "immer";
import demoApi from "../../api/dashboard/demoApi";
import AppRoutes from "../AppRoutes";
import FullScreenLoaderWithMessage from "../../components/loaders/FullScreenLoaderWithMessage";
import AppContext from "../../state/AppContext";
import GenericApiResult from "../../types/dtos/generic/GenericApiResult";
import {
  DemoResetInfo,
  DemoResetJourneyOption,
  DemoUserResetState,
  GetDemoJourneyResetDefaultState,
  GetDemoTaskTypeResetDefaultState,
} from "../../types/demo/DemoUserResetTypes";
import UserContext from "../../state/UserContext";
import { DemoUserResetOptions } from "../../components/demo/DemoUserResetOptions";
import { MainContainer } from "../../components/layout";

export const DemoManagerReset = () => {
  // Constants
  const appContext = useContext(AppContext);
  const userContext = useContext(UserContext);
  const auth = useAuth();
  const navigate = useNavigate();
  const api = new demoApi(auth.user?.access_token);

  // State
  const [isCallingApi, setIsCallingApi] = useState<boolean>(false);
  const [userState, setUserState] = useState<DemoUserResetState[]>([]);
  const [availableJourneys, setAvailableJourneys] = useState<
    DemoResetJourneyOption[]
  >([]);

  // Side Effects
  useEffectOnce(() => {
    // Set the page title
    appContext.setPageTitle("Demo Reset");
    setDefaultState();
  });

  // Functions

  /** Get the managed users and construct the default reset state */
  const setDefaultState = () => {
    const onSuccess = (demoInfo: DemoResetInfo) => {
      const newState: DemoUserResetState[] = [];

      if (demoInfo.demoUsers) {
        demoInfo.demoUsers.forEach((user) => {
          newState.push({
            journey: GetDemoJourneyResetDefaultState(),
            resetType: "NONE",
            taskTypes: GetDemoTaskTypeResetDefaultState(user),
            userId: user.userId,
            fullName: user.fullName,
          });
        });
      }

      setUserState(newState);
      setAvailableJourneys(
        demoInfo.availableClientSentJourneys.map((j) => ({
          journeyRef: j.key,
          title: j.value,
        }))
      );
      setIsCallingApi(false);
    };

    const onError = (error: any) => {
      setIsCallingApi(false);
      console.error("Unable to load managed users", error);
    };

    setIsCallingApi(true);
    api.getDemoResetInfo(onSuccess, onError);
  };

  const handleUserStateChange = (updatedUser: DemoUserResetState) => {
    const nextState = produce(userState, (draft: DemoUserResetState[]) => {
      const matchIndex = draft.findIndex(
        (x) => x.userId === updatedUser.userId
      );
      if (matchIndex >= 0) {
        draft[matchIndex] = updatedUser;
      }
    });

    setUserState(nextState);
  };

  // Events
  const onSubmitClick = () => {
    const onApiSuccess = (data: GenericApiResult) => {
      setIsCallingApi(false);
      if (data.successful) {
        navigate(AppRoutes.yourJourney.root);
      } else {
        onApiError(data.errorMessage);
      }
    };

    const onApiError = (error: any) => {
      alert("An error occurred. Please try again by refreshing this page.");
      setIsCallingApi(false);
      console.error(error);
    };

    // On load, call the API to reset the demo
    setIsCallingApi(true);

    api.resetManagerDemo(userState, onApiSuccess, onApiError);
  };

  // Render

  if (isCallingApi) {
    return <FullScreenLoaderWithMessage message="Please wait..." />;
  }

  return (
    <MainContainer>
      <p className="my-4">
        Choose how you'd like to reset the data for yourself and the people you
        manage:
      </p>

      <div className="mb-2">
        {userState.map((user) => {
          const isManager = user.userId === userContext.user.id;
          return (
            <DemoUserResetOptions
              key={userContext.user.id}
              isManager={isManager}
              availableJourneys={availableJourneys}
              currentValues={user}
              onChangeValues={handleUserStateChange}
            />
          );
        })}
      </div>
      <div className="text-right">
        <Link to={AppRoutes.home} className="mr-4 hover:underline">
          Cancel
        </Link>
        <button className="btn-primary" onClick={onSubmitClick}>
          Reset
        </button>
      </div>
    </MainContainer>
  );
};
