import produce from "immer";
import { t } from "i18next";
import cx from "classnames";
import {
  DemoResetJourneyOption,
  DemoUserAdvancedTaskResetType,
  DemoUserJourneyResetType,
  DemoUserResetState,
} from "../../types/demo/DemoUserResetTypes";
import { useContext } from "react";
import UserContext from "../../state/UserContext";

interface CustomDemoResetSettingsProps {
  availableJourneys: DemoResetJourneyOption[];
  currentValues: DemoUserResetState;
  onChangeValues: (newValues: DemoUserResetState) => void;
}

export const CustomDemoResetSettings = ({
  availableJourneys,
  currentValues,
  onChangeValues,
}: CustomDemoResetSettingsProps) => {
  // Constants
  const userContext = useContext(UserContext);

  // Functions

  /** When the option for the journey reset type is changed */
  const handleJourneyResetTypeChange = (newType: DemoUserJourneyResetType) => {
    const newState = produce(currentValues, (draft) => {
      if (draft.journey) {
        draft.journey.resetType = newType;
        if (newType === "SEND-NEW") {
          // Select the first available journey by default
          draft.journey.newJourneyRef = availableJourneys[0].journeyRef;
        }
      } else {
        draft.journey = {
          resetType: newType,
          newJourneyRef: null,
          clearCompleted: false,
        };
      }
    });
    onChangeValues(newState);
  };

  const handleNewJourneySelected = (newJourneyRef: string) => {
    const newState = {
      ...currentValues,
      journey: { ...currentValues.journey, newJourneyRef },
    };
    onChangeValues(newState);
  };

  const handleClearJourneyHistoryCheckboxChange = (isChecked: boolean) => {
    const newState = {
      ...currentValues,
      journey: { ...currentValues.journey, clearCompleted: isChecked },
    };
    onChangeValues(newState);
  };

  /** When the option for the task type is changed */
  const handleTaskTypeChange = (
    taskTypeId: string,
    resetType: DemoUserAdvancedTaskResetType
  ) => {
    const newState = produce(currentValues, (draft) => {
      const taskType = draft.taskTypes!.find(
        (t) => t.taskTypeId === taskTypeId
      );
      if (taskType) {
        taskType.resetType = resetType;
      }
    });
    onChangeValues(newState);
  };

  // Render values

  const dropdownCommonClassNames =
    "w-full px-2 py-1 border-gray-300 rounded-md focus:border-teal-600 focus:outline-none focus:ring-0";

  // Hide the journey reset section for the logged in user, if they don't have a manager/journey manager
  const showJourneyResetSection =
    currentValues.userId !== userContext.user.id ||
    !userContext.user.isFullyManagerless;

  return (
    <div className="border-t border-gray-200 pt-2 px-1 mb-2">
      {showJourneyResetSection && (
        <div>
          <div className="text-xs uppercase">Journeys</div>
          <div className="pl-1">
            <div className="mb-1">
              <select
                className={cx(dropdownCommonClassNames, "mt-1")}
                onChange={(e) =>
                  handleJourneyResetTypeChange(
                    e.target.value as DemoUserJourneyResetType
                  )
                }
              >
                <option value="NONE">No changes</option>
                <option value="CANCEL-CURRENT">Cancel current</option>
                <option value="SEND-NEW">Send new</option>
              </select>
              {currentValues.journey !== null &&
                currentValues.journey.resetType === "SEND-NEW" && (
                  <select
                    className={cx(dropdownCommonClassNames, "mt-1")}
                    onChange={(e) => handleNewJourneySelected(e.target.value)}
                  >
                    {availableJourneys.map((journey) => (
                      <option
                        key={journey.journeyRef}
                        value={journey.journeyRef}
                      >
                        {journey.title}
                      </option>
                    ))}
                  </select>
                )}
              {currentValues.journey !== null &&
                currentValues.journey.resetType !== "NONE" && (
                  <div className="mt-1 pl-0.5">
                    <input
                      type="checkbox"
                      className="mr-2 focus:border-teal-600 focus:outline-none focus:ring-0"
                      id={`chkClearCompletedJourneys_${currentValues.userId}`}
                      onChange={(e) =>
                        handleClearJourneyHistoryCheckboxChange(
                          e.target.checked
                        )
                      }
                    />
                    <label
                      htmlFor={`chkClearCompletedJourneys_${currentValues.userId}`}
                    >
                      Clear all journey history
                    </label>
                  </div>
                )}
            </div>
          </div>
        </div>
      )}

      {currentValues.taskTypes !== null &&
        currentValues.taskTypes.length > 0 && (
          <>
            {showJourneyResetSection && <hr className="my-2" />}
            <div>
              <div className="text-xs uppercase">Tasks</div>
              <div className="pl-1">
                {currentValues.taskTypes.map((taskType) => {
                  const matchingTaskType =
                    userContext.user.client.taskTypes.find(
                      (tt) => tt.id === taskType.taskTypeId
                    );
                  const taskTypeName = matchingTaskType
                    ? t(matchingTaskType.pluralNameTranslationKeyIdentifier)
                    : "Unknown Task Type";
                  const fieldId = `taskType_${taskType.taskTypeId}_${currentValues.userId}`;
                  return (
                    <div className="py-1">
                      <label className="mb-1" htmlFor={fieldId}>
                        {taskTypeName}
                      </label>
                      <select
                        id={fieldId}
                        className={dropdownCommonClassNames}
                        onChange={(e) =>
                          handleTaskTypeChange(
                            taskType.taskTypeId,
                            e.target.value as DemoUserAdvancedTaskResetType
                          )
                        }
                      >
                        <option value="NONE">No changes</option>
                        <option value="CANCEL-ALL">Clear all</option>
                        <option value="GENERATE-NEW-SET">
                          Generate new set
                        </option>
                      </select>
                    </div>
                  );
                })}
              </div>
            </div>
          </>
        )}
    </div>
  );
};
