import cx from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/pro-solid-svg-icons";
import { Badge } from "../common";
import {
  DemoResetJourneyOption,
  DemoUserResetState,
  DemoUserResetType,
  GetDemoJourneyResetDefaultState,
  GetDemoTaskTypeResetDefaultState,
} from "../../types/demo/DemoUserResetTypes";
import { CustomDemoResetSettings } from "./CustomDemoResetSettings";

interface DemoUserResetOptionsProps {
  isManager: boolean;
  availableJourneys: DemoResetJourneyOption[];
  currentValues: DemoUserResetState;
  onChangeValues: (newValues: DemoUserResetState) => void;
}

export const DemoUserResetOptions = ({
  isManager,
  availableJourneys,
  currentValues,
  onChangeValues,
}: DemoUserResetOptionsProps) => {
  const handleResetTypeChange = (selectedResetType: DemoUserResetType) => {
    const newState = { ...currentValues, resetType: selectedResetType };
    if (selectedResetType !== "CUSTOM") {
      newState.journey = GetDemoJourneyResetDefaultState();
      newState.taskTypes = !currentValues.taskTypes
        ? null
        : currentValues.taskTypes.map((item) => ({
            ...item,
            resetType: "NONE",
          }));
    }
    onChangeValues(newState);
  };

  return (
    <div className="mb-4 py-2 bg-slate-100 rounded-md border border-slate-300">
      <div className="flex flex-row gap-1 border-b border-slate-300 px-4 pb-2">
        <span className="text-lg font-semibold">{currentValues.fullName}</span>
        {isManager && (
          <div>
            <Badge text="You" classNames="bg-slate-700 text-white" />
          </div>
        )}
      </div>
      <div className="px-4 pt-4 pb-2">
        <div className="grid grid-cols-3 gap-2">
          <DemoUserResetOptionBox
            resetType="NONE"
            isSelected={currentValues.resetType === "NONE"}
            title="No reset"
            description="Makes no changes to this user's journey and tasks"
            onSelect={handleResetTypeChange}
          />
          <DemoUserResetOptionBox
            resetType="PRODUCT-DEMO"
            isSelected={currentValues.resetType === "PRODUCT-DEMO"}
            title="Product demo reset"
            description="Re-open any started journeys so they can be viewed and completed again"
            onSelect={handleResetTypeChange}
          />
          <DemoUserResetOptionBox
            resetType="CUSTOM"
            isSelected={currentValues.resetType === "CUSTOM"}
            title="Custom reset"
            description="Choose exactly how you want to reset this user"
            onSelect={handleResetTypeChange}
          >
            {/* Display custom reset options only when CUSTOM is selected */}
            {currentValues.resetType === "CUSTOM" && (
              <CustomDemoResetSettings
                availableJourneys={availableJourneys}
                currentValues={currentValues}
                onChangeValues={onChangeValues}
              />
            )}
          </DemoUserResetOptionBox>
        </div>
      </div>
    </div>
  );
};

const DemoUserResetOptionBox = ({
  resetType,
  title,
  description,
  isSelected,
  onSelect,
  children = undefined,
}: {
  resetType: DemoUserResetType;
  title: string;
  description: string;
  isSelected: boolean;
  onSelect: (selectedResetType: DemoUserResetType) => void;
  children?: React.ReactNode | undefined;
}) => {
  const handleSelect = () => {
    onSelect(resetType);
  };

  const buttonCommonClassNames = "px-2 py-1 rounded-sm shadow-md font-semibold";

  return (
    <div
      className={cx(
        "bg-white border rounded-md px-2 pb-2 shadow-sm",
        isSelected ? "border-teal-500" : "border-slate-200"
      )}
    >
      <header className="text-center text-md font-semibold mt-3 mb-2">
        {title}
      </header>
      <div className="text-center mb-2 text-sm">
        {isSelected ? (
          <button
            disabled
            className={cx(
              buttonCommonClassNames,
              "bg-teal-500 cursor-not-allowed text-white"
            )}
          >
            <FontAwesomeIcon icon={faCheck} size="xs" className="mr-1" />
            <span>Selected</span>
          </button>
        ) : (
          <button
            onClick={handleSelect}
            className={cx(
              buttonCommonClassNames,
              "text-gray-500 border bg-gray-50 border-gray-300 cursor-pointer hover:bg-teal-200 hover:border-teal-400 hover:text-teal-700 hover:shadow-lg"
            )}
          >
            Select
          </button>
        )}
      </div>

      <p className="text-center mb-2 text-gray-500">{description}</p>
      {isSelected && children !== undefined ? children : null}
    </div>
  );
};
