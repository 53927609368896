export const linkifyHelper = {
  convertRawValueForHtmlDisplay(
    value: string,
    requireWhitespaceAfterUrls: boolean
  ): string {
    let output = value;
    output = linkifyHelper.replaceNewLineWithBrTag(output);
    output = linkifyHelper.linkifyRawValue(output, requireWhitespaceAfterUrls);
    return output;
  },

  convertRawValueForSaving(value: string): string {
    // Remove anchor tags used for displaying clickable links, leaving just the href to be stored down
    let output = value.replace(
      /<a[^>]*href=['"]([^'"]*)['"][^>]*>(.*?)<\/a>/gi,
      "$1"
    );

    // Replace DIV tags with BR tags
    output = linkifyHelper.replaceDivsWithBrTags(output);

    // Replace BR tags with new line characters
    output = output.replace(/<br\s*\/?>/gi, "\n");

    // Replace nbsp with space
    output = linkifyHelper.replaceNbspWithSpace(output);

    return output;
  },

  replaceNbspWithSpace(input: string) {
    if (!input) {
      return input;
    }
    return input.replace(/&nbsp;/gi, " ");
  },

  replaceDivsWithBrTags(input: string): string {
    const regex = /<div[^>]*>(.*?)<\/div>/g;

    // Replacement function
    var output = input.replace(regex, function (match, p1) {
      return "<br>" + p1;
    });

    return output;
  },

  replaceNewLineWithBrTag(input: string): string {
    return input.replace(/\n/gi, "<br>");
  },

  linkifyRawValue(input: string, requireWhitespaceAfterUrls: boolean): string {
    // A regex to match any urls, followed by a space, but not already within an anchor tag
    const regex = requireWhitespaceAfterUrls
      ? /(?!<a[^>]*>[^<])(((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www\.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_:]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?))(?=\s)(?![^<]*<\/a>)/gi
      : /(?!<a[^>]*>[^<])((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www\.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_:]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)(?![^<]*<\/a>)/gi;
    let output = input.replace(regex, function (match) {
      const linkUrl = linkifyHelper.getLinkUrlValue(match);
      const linkText = linkifyHelper.getLinkDisplayValue(match);
      return (
        `<a href="${linkUrl}">${linkText}</a>` +
        // Additional whitespace so the carat moves to a whitespace after the link
        (requireWhitespaceAfterUrls ? "&nbsp;" : "")
      );
    });

    output = linkifyHelper.ensureConsistentAnchorTags(output);
    return output;
  },

  /** Apply the attributes to any a tags, such as class names to underline the links */
  ensureConsistentAnchorTags(inputString: string): string {
    if (!inputString) {
      return inputString;
    }

    return inputString.replace(
      /<a\s+[^>]*href=["']([^"']+)["'][^>]*>(.*?)<\/a>/gi,
      (match, href, text) =>
        `<a href="${href}" title="${href}" contentEditable="false" class="underline text-blue-600" target="_blank">${text}</a>`
    );
  },

  getLinkUrlValue(input: string): string {
    const pathParts = input.split("://");
    // If already has protocol then return it
    if (pathParts.length > 1) {
      return input;
    } else {
      // Assume protocol and return link with it
      return "https://" + input;
    }
  },

  getLinkDisplayValue(input: string): string {
    const maxLinkDisplayNameLength = 25;
    const pathParts = input.split("://");
    let linkMainPath = "";
    if (pathParts.length > 1) {
      linkMainPath = pathParts[1].trim();
    } else {
      linkMainPath = pathParts[0].trim();
    }
    let output = linkMainPath.substring(
      0,
      Math.min(maxLinkDisplayNameLength, linkMainPath.length)
    );
    if (linkMainPath.length > maxLinkDisplayNameLength) {
      output += "...";
    }
    // If the link text ends with a slash, remove it
    if (output.length > 0 && output.endsWith("/")) {
      output = output.substring(0, output.length - 1);
    }
    return output;
  },
};

export default linkifyHelper;
