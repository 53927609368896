import React, { useEffect, useRef } from "react";
import ContentEditable, { ContentEditableEvent } from "react-contenteditable";
import cx from "classnames";
import { QuestionAnswer, ValidationResult } from "../../types/forms";
import ValidationWarning from "./ValidationWarning";
import linkifyHelper from "../../helpers/linkifyHelper";

interface SmartTextAreaProps {
  /** The onChange event, for handling state changes */
  onChange(newValue: string): void;
  /** An event to call when the input loses focus */
  onBlur?(
    latestValue: string,
    nonStateValue?: QuestionAnswer | null
  ): void | undefined;
  /** The current value for the textarea */
  value: string | undefined;
  /** The Id attribute for the input element, to match the Label */
  inputId?: string;
  /** The css class names to apply */
  className?: string;
  /** Whether or not the editable area should grow in height */
  autoGrowHeight?: boolean | undefined;
  /** The Placeholder attribute value for the form element */
  placeholder?: string | undefined;
  /** A ref for calling methods on the textarea element, e.g. to focus it */
  inputRef?: React.RefObject<HTMLTextAreaElement> | undefined;
  /** Whether or not to display the validation warnings */
  showValidationErrors?: boolean;
  /** If validation has been run, this is the validity plus any errors */
  validationResult?: ValidationResult | null;
  isReadOnly?: boolean;
  maxLength?: number | undefined;
}

/** A TextArea element which can auto-grow up to a maximum number of rows
 * to avoid the user having to scroll as soon as the text gets quite long
 */
const SmartTextArea = ({
  onChange,
  onBlur = undefined,
  showValidationErrors = false,
  validationResult = null,
  value = "",
  inputId = "",
  className = "rounded-md border-0 border-none",
  autoGrowHeight = true,
  placeholder = "",
  inputRef = undefined,
  isReadOnly = false,
  maxLength = undefined,
}: SmartTextAreaProps) => {
  const stateValue = useRef({
    display: "",
    raw: "",
    valueToSave: "",
  });

  const contentEditableRef = useRef<HTMLElement>(null);

  useEffect(() => {
    // If there is a value passed in and the current raw value is empty, update the state values
    if (value !== "" && stateValue.current.raw === "") {
      updateStateValues(value, false);
      if (contentEditableRef.current) {
        contentEditableRef.current.innerHTML = stateValue.current.display;
      }
    }
  }, [value]);

  const handleChange = (e: ContentEditableEvent) => {
    if (userIsPerformingTextSelection()) {
      // Don't run the code after this if the user is just selecting text
      return;
    }
    updateStateValues(e.target.value, true);
    onChange(stateValue.current.valueToSave);
  };

  const userIsPerformingTextSelection = (): boolean => {
    const contentDiv = contentEditableRef.current;

    if (!contentDiv) {
      return false;
    }

    // Get the current selection
    const selection = window.getSelection();

    if (!selection) {
      return false;
    }

    const range = selection.rangeCount > 0 ? selection.getRangeAt(0) : null;

    // If there's any selection, return true
    return range !== null && selection.toString().length > 0;
  };

  const handleBlur = () => {
    // First convert any nbsp; to spaces (we only do this on blur to avoid issues with the cursor position)
    const rawTextValue = linkifyHelper.replaceNbspWithSpace(
      stateValue.current.raw
    );

    // To handle links being pasted in at the end of the content, without needing a whitespace after to trigger the linkify,
    // we run the same function on blur to ensure all links are clickable
    updateStateValues(rawTextValue, false);

    // If the display HTML has changed, update the contentEditable div
    // (for some reason, just setting the value of `stateValue.current.display` doesn't update the contentEditable div on blur)
    if (
      contentEditableRef.current &&
      contentEditableRef.current.innerHTML !== stateValue.current.display
    ) {
      contentEditableRef.current.innerHTML = stateValue.current.display;
    }

    if (onBlur) {
      onBlur(stateValue.current.valueToSave);
    }
  };

  const updateStateValues = (rawValue: string, requireWhitespace: boolean) => {
    stateValue.current.raw = rawValue;
    stateValue.current.display = linkifyHelper.convertRawValueForHtmlDisplay(
      rawValue,
      requireWhitespace
    );
    stateValue.current.valueToSave =
      linkifyHelper.convertRawValueForSaving(rawValue);
  };

  return (
    <div>
      {showValidationErrors && validationResult && (
        <ValidationWarning
          isValid={validationResult.isValid}
          errors={validationResult.errors}
        />
      )}
      <div className={cx("hidden print:block print-avoid-break", className)}>
        {value ? value : ""}
      </div>
      <ContentEditable
        id={inputId}
        innerRef={contentEditableRef}
        html={stateValue.current.display} // innerHTML of the editable div
        disabled={isReadOnly} // use true to disable editing
        onChange={handleChange} // handle innerHTML change
        onBlur={handleBlur}
        className={cx(
          "p-2 focus:outline-none focus:ring-0 disabled:text-gray-600 min-h-16 print:hidden",
          className,
          isReadOnly ? "cursor-not-allowed" : "",
          autoGrowHeight ? "max-h-32 overflow-y-auto" : ""
        )}
        placeholder={placeholder}
        tagName="div"
      />
    </div>
  );
};

export default SmartTextArea;
