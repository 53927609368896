import { UserBasicDetailsDto } from "../dtos/generic";
import { KeyValuePair } from "../generic";

/** The object returned from the API */
export interface DemoResetInfo {
  demoUsers: DemoUserInfo[];
  /** Key is the JourneyRef GUID, string is the title */
  availableClientSentJourneys: KeyValuePair<string, string>[];
}

/** Information about a user, returned from the API, to display on the demo reset page */
export interface DemoUserInfo extends UserBasicDetailsDto {
  taskTypeIds: string[] | null;
}

/** Information about a user's reset state which will be sent to the API */
export interface DemoUserResetDto {
  userId: number;
  resetType: DemoUserResetType;
  journey: DemoUserResetJourneyDto;
  taskTypes: DemoUserResetAdvancedTaskDto[] | null;
}

export interface DemoUserResetState extends DemoUserResetDto {
  fullName: string;
}

export interface DemoUserResetJourneyDto {
  resetType: DemoUserJourneyResetType;
  newJourneyRef: string | null;
  clearCompleted: boolean;
}

export interface DemoUserResetAdvancedTaskDto {
  taskTypeId: string;
  resetType: DemoUserAdvancedTaskResetType;
}

export type DemoUserResetType = "NONE" | "PRODUCT-DEMO" | "CUSTOM";

export type DemoUserAdvancedTaskResetType =
  | "NONE"
  | "CANCEL-ALL"
  | "GENERATE-NEW-SET";

export type DemoUserJourneyResetType = "NONE" | "CANCEL-CURRENT" | "SEND-NEW";

export interface DemoResetJourneyOption {
  journeyRef: string;
  title: string;
}

export const GetDemoJourneyResetDefaultState = (): DemoUserResetJourneyDto => {
  return {
    resetType: "NONE",
    newJourneyRef: null,
    clearCompleted: false,
  };
};

export const GetDemoTaskTypeResetDefaultState = (
  user: DemoUserInfo
): DemoUserResetAdvancedTaskDto[] | null => {
  if (user.taskTypeIds) {
    return user.taskTypeIds.map((taskTypeId) => ({
      taskTypeId,
      resetType: "NONE",
    }));
  }

  return null;
};
