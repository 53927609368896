import { t } from "i18next";
import cx from "classnames";
import { taskTypeHelper } from "../../helpers";
import { FormType } from "../../types/forms";
import {
  EditableGoal,
  EditableTask,
  TaskIdentifierType,
} from "../../types/tasks/EditableTasks";
import { FormattedDate, IconButton } from "../common";
import TaskTypeIcon from "./TaskTypeIcon";

interface TasksListProps {
  /** The tasks to manage */
  tasks: Array<EditableTask<TaskIdentifierType>>;
  /** Whether the items can be edited/deleted */
  isReadOnly: boolean;
  /** Whether this is being rendered in a journey or a collab doc */
  formType: FormType;
  /** Whether or not to distinguish rolled over goals from fresh new goals. Used when goal review and goal setting are in the same form */
  separateRolledOverGoals?: boolean;
  onDelete(taskIndex: number, editTask: EditableTask<TaskIdentifierType>): void;
  onEdit(taskIndex: number, editTask: EditableTask<TaskIdentifierType>): void;
}

interface TasksListInnerContentProps {
  tasks: Array<EditableTaskListItem>;
  allowDelete: boolean;
  allowEdit: boolean;
  textColourClassName: string;
  /** Whether this is being rendered in a journey or a collab doc */
  formType: FormType;
  onDelete(taskIndex: number, editTask: EditableTask<TaskIdentifierType>): void;
  onEdit(taskIndex: number, editTask: EditableTask<TaskIdentifierType>): void;
}

interface EditableTaskListItem {
  task: EditableTask<TaskIdentifierType>;
  /** The index in the source list, even if they've been split into separate "Rolled over" and "New" lists in the UI */
  index: number;
}

interface EditableGoalListItem {
  goal: EditableGoal<string>;
  /** The index in the source list, even if they've been split into separate "Rolled over" and "New" lists in the UI */
  index: number;
}

function TasksListInnerContent({
  tasks,
  onDelete,
  onEdit,
  formType,
  allowDelete,
  allowEdit,
  textColourClassName,
}: TasksListInnerContentProps) {
  if (tasks.length === 0) return null;
  const taskBackgroundColour =
    formType === "JOURNEY" ? "bg-white/30" : "bg-gray-200";

  return (
    <div className="mt-2">
      <div className="hidden lg:flex flex-row text-xs px-2">
        <div className="flex-none w-6"></div>
        <div className="flex-grow px-1">{t("Tasks.Common.TaskName")}</div>
        <div className="flex-none px-1 text-center w-28">
          {t("Tasks.Common.TargetDate")}
        </div>
        <div className="flex-none px-1 w-20"></div>
      </div>
      {tasks.map((taskItem) => {
        // Don't render deleted items
        if (taskItem.task.modifyStatus === "DELETED") return null;

        return (
          <div
            className={cx(
              "my-1 py-1 w-full px-2 lg:flex lg:flex-row rounded-md",
              textColourClassName,
              taskBackgroundColour
            )}
            key={`task_${taskItem.index}`}
          >
            {/* Large screens */}
            <div className="hidden lg:block lg:flex-none lg:w-6 text-center">
              <TaskTypeIcon
                taskType={taskItem.task.taskType}
                colourClassName={textColourClassName}
                iconSize="sm"
              />
            </div>
            <div className="hidden lg:block flex-grow px-1 overflow-x-hidden">
              <button
                className="text-left cursor-pointer"
                onClick={() => onEdit(taskItem.index, taskItem.task)}
              >
                {taskItem.task.title}
              </button>
            </div>
            <div className="hidden lg:block lg:flex-none lg:px-1 lg:text-center lg:w-28">
              <FormattedDate
                date={taskItem.task.targetDate!}
                displayMode="DATE-ONLY"
              />
            </div>
            {/* Small screens */}
            <div className="lg:hidden">
              <div className="mb-1 font-semibold">
                <button
                  onClick={() => onEdit(taskItem.index, taskItem.task)}
                  className="text-left"
                >
                  {taskItem.task.title}
                </button>
              </div>
              <div className="mb-1">
                <span className="text-cs italic mr-1">
                  {t("Tasks.Common.TaskType")}:
                </span>
                <TaskTypeIcon
                  taskType={taskItem.task.taskType}
                  colourClassName={textColourClassName}
                  iconSize="sm"
                />
                <span className="ml-1">
                  {t(
                    taskTypeHelper.getTranslationKeyForTaskType(
                      taskItem.task.taskType
                    )
                  )}
                </span>
              </div>
              <div>
                <span className="text-cs italic mr-1">
                  {t("Tasks.Common.TargetDate")}:
                </span>
                <FormattedDate
                  date={taskItem.task.targetDate!}
                  displayMode="DATE-ONLY"
                />
              </div>
            </div>
            {(allowEdit || allowDelete) && (
              <>
                {/* Large screens */}
                <div className="hidden lg:block flex-none w-20 text-right px-1">
                  {allowEdit && (
                    <IconButton
                      buttonType="EDIT"
                      displayMode="ICON-ONLY"
                      buttonClassName="px-1 mr-2"
                      iconClassName={textColourClassName}
                      onClick={() => onEdit(taskItem.index, taskItem.task)}
                    />
                  )}
                  {allowDelete && (
                    <IconButton
                      buttonType="DELETE"
                      displayMode="ICON-ONLY"
                      buttonClassName="px-1 hover:text-red-600"
                      iconClassName={textColourClassName}
                      onClick={() => onDelete(taskItem.index, taskItem.task)}
                    />
                  )}
                </div>
                {/* Small screens */}
                <div className="lg:hidden mt-2 mb-1 flex-none">
                  <div className="flex flex-row">
                    {allowEdit && (
                      <div className="basis-1/2 pr-1">
                        <IconButton
                          buttonType="EDIT"
                          displayMode="WITH-TEXT"
                          buttonClassName="px-1 w-full"
                          iconClassName="text-gray-800"
                          onClick={() => onEdit(taskItem.index, taskItem.task)}
                        />
                      </div>
                    )}
                    {allowDelete && (
                      <div className="basis-1/2 pl-1">
                        <IconButton
                          buttonType="DELETE"
                          displayMode="WITH-TEXT"
                          buttonClassName="px-1 w-full"
                          iconClassName="text-gray-800"
                          onClick={() =>
                            onDelete(taskItem.index, taskItem.task)
                          }
                        />
                      </div>
                    )}
                  </div>
                </div>
              </>
            )}
          </div>
        );
      })}
    </div>
  );
}

function TasksList({
  tasks,
  isReadOnly,
  onDelete,
  onEdit,
  formType,
  separateRolledOverGoals = false,
}: TasksListProps) {
  if (tasks.length === 0) return null;

  const textColourClassName = formType === "JOURNEY" ? "" : "";

  if (separateRolledOverGoals) {
    const getGoalSection = (
      goalItems: EditableGoalListItem[],
      isRolledOverGoals: boolean,
      sectionTitle: string,
      noGoalsText: string
    ) => {
      // Convert to a format that the TasksListInnerContent component can use
      const convertedListItems: EditableTaskListItem[] = goalItems.map((x) => ({
        task: x.goal,
        index: x.index,
      }));

      return (
        <>
          <h4 className={cx("font-semibold", textColourClassName)}>
            {sectionTitle}
          </h4>
          {goals && goals.length > 0 && (
            <TasksListInnerContent
              tasks={convertedListItems}
              allowDelete={!isReadOnly && !isRolledOverGoals}
              allowEdit={!isReadOnly}
              onEdit={onEdit}
              onDelete={onDelete}
              formType={formType}
              textColourClassName={textColourClassName}
            />
          )}
          {(!goals || goals.length === 0) && (
            <p className={cx("pl-1 m-0 mt-1", textColourClassName)}>
              {noGoalsText}
            </p>
          )}
        </>
      );
    };

    const goals: EditableGoalListItem[] = tasks
      .filter((x) => x.taskType === "GOAL" && x.modifyStatus !== "DELETED")
      .map((x, ix) => ({ goal: x as EditableGoal<string>, index: ix }));
    const newGoals = goals.filter((x) => x.goal.rolloverFromTaskId === null);
    const rolloverGoals = goals.filter(
      (x) => x.goal.rolloverFromTaskId !== null
    );

    return (
      <div>
        <div className="mb-4">
          {getGoalSection(
            rolloverGoals,
            true,
            t("Forms.Goals.Setting.OngoingGoalsSection.Heading"),
            t("Forms.Goals.Setting.OngoingGoalsSection.EmptyMessage")
          )}
        </div>
        <div>
          {getGoalSection(
            newGoals,
            false,
            t("Forms.Goals.Setting.NewGoalsSection.Heading"),
            t("Forms.Goals.Setting.NewGoalsSection.EmptyMessage")
          )}
        </div>
      </div>
    );
  }

  // Rendering one list, not separate rolled over goals/new goals
  const taskListItems = tasks.map((t, ix) => ({ task: t, index: ix }));
  return (
    <TasksListInnerContent
      tasks={taskListItems}
      allowDelete={!isReadOnly}
      allowEdit={!isReadOnly}
      onEdit={onEdit}
      onDelete={onDelete}
      formType={formType}
      textColourClassName={textColourClassName}
    />
  );
}

export default TasksList;
